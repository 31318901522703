.sticky-sidebar {
  position: relative;
  width:100%;
  max-width: 100%;
  background-color: white;
}

.sidebar-content {
  padding-left: 8%;
  padding-right: 8%;
  margin-top: 12%;
  border-right: 1px solid lightgray;
  display: flex; 
  flex-direction: column; 
  align-items: center;
}



@media screen and (min-width: 992px){
  .sticky-sidebar {
    position: fixed;
    width:28%;
    background-color: white;
  }

  .sidebar-content {
    padding-left: 8%;
    padding-right: 8%;
    margin-top: 12%;
    border-right: 1px solid lightgray;
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
  }
}



.profile-picture {
  position: relative;
  width: 50%;
  padding-bottom: 5%;
}

.summary {
  color:rgb(128, 128, 128);
}

.page-nav {
  padding-top: 6%; 
  padding-bottom: 6%;
}

.nav-icons {
  display: flex;
  flex-wrap: wrap-reverse;
}

.nav-icon {
  text-align: center;
  margin: 2%;
}

.nav-icon:hover {
  background-color: lightgray !important;
}

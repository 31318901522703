html {
  font-size: 16px;
  overflow-y:scroll;
  overflow-x:hidden;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 0;
}

.main {
  padding: 8%;
  margin-bottom: 3%;
}

.appRow {
  display: flex;  
  flex-direction: column;
  justify-content: space-between;
}

.col-4-custom {
  width: 100%; 
}

.col-8-custom {
  width: 100%;
}

@media screen and (min-width: 992px){

  .main {
    padding: 3% 3% 0 0;
  }

  .appRow {
    display: flex; 
    flex-direction: row; 
  }

  .col-4-custom {
    width: 30%; 
  }
  
  .col-8-custom {
    width: 70%;
  }

}
.projects {
  display: flex; 
  flex-direction: column; 
}

.project-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}


.card {
  border:none;
  margin: 1rem;
  width: 100%;
}

.card-horizontal {
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

.btn-light {
  background-color: lightgray;
  margin: 0.2rem;
}

.tools {
  color: darkslategray;
}



@media screen and (min-width: 992px){
  .card-horizontal {
    flex-direction: row;
  }

  .project-thumbnail {
    max-height: 16vw;
    max-width: 24vw;
    min-height: 16vw;
    min-width: 24vw;
    object-fit: cover; 
  }
}


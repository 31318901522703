iframe {
  border: none;
  width: 100%; 
  height: 50vw;
}

.picture {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vw;
  margin-bottom: 2vw;
  width: 100%;
  border: 1px solid lightgray;
}

.sections {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media screen and (min-width: 992px) {
  .picture {
    width: 30%;
  }

  .coursework {
    flex-basis: 48%;
  }
  .timeline {
    padding: 1%;
    flex-basis: content;
  }
}